<template>
  <v-data-table
    :headers="headers"
    :items="profiles"
    :items-per-page="5"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>
          Profils ONG
        </v-toolbar-title>
        <v-spacer />
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs}">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Nouveau
            </v-btn>
            <download-excel
              class="mr-3 mb-2 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default primary"
              :data="profiles"
              :fields="json_fields"
              type="csv"
            >
              Download CSV
            </download-excel>
            <download-excel
              class="mr-3 mb-2 v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default primary"
              :data="profiles"
              :fields="json_fields"
              type="xls"
            >
              Download Excel
            </download-excel>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="profileEdit.libelle_organisation"
                      label="Organisation"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="profileEdit.directeur"
                      label="Nom et Prénom(directeur)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="profileEdit.email"
                      label="Email(directeur)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="profileEdit.telephone"
                      label="Téléphone(directeur)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="profileEdit.adresses"
                      label="Adresse"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="profileEdit.longitude_organisations"
                      label="Longitude"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="profileEdit.latitude_organisations"
                      label="Latitude"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-file-input
                      v-model="profileEdit.logo_organisations"
                      chips
                      small-chips
                      truncate-length="15"
                      label="Logo ONG"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darke-1"
                @click="close"
              >
                Fermer
              </v-btn>
              <v-btn
                color="blue darke-1"
                @click="saveProfile"
              >
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="500px"
        >
          <v-card>
            <v-card-title class="text-h5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-row>
        <v-col
          class="ml-3"
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-col>
      </v-row>
    </template>

    <template v-slot:item.actions="{item}">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:item.logo_organisations="{item}">
      <v-img
        :lazy-src="item.logo_organisations"
        max-height="150"
        max-width="250"
        :src="item.logo_organisations"
      />
    </template>
  </v-data-table>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import profilOng from '@/services/profile-ong.js'
  export default {
    data () {
      return {
        dialog: false,
        dialogDelete: false,
        json_fields: {
          Organisation: 'libelle_organisation',
          Directeur: 'directeur',
          Adresse: 'adresses',
          Email: 'email',
          Téléphone: 'telephone',
        },
        search: '',
        headers: [
          { text: 'Actions', value: 'actions', sortable: false },
          {
            text: 'Logo',
            value: 'logo_organisations',
          },
          {
            text: 'Organisation',
            value: 'libelle_organisation',
          },
          {
            text: 'Directeur',
            value: 'directeur',
          },
          {
            text: 'Adresse',
            value: 'adresses',
          },
          {
            text: 'Email',
            value: 'email',
          },
          {
            text: 'Téléphone',
            value: 'telephone',
          },
          {
            text: 'Longitude',
            value: 'longitude_organisations',
          },
          {
            text: 'Latitude',
            value: 'latitude_organisations',
          },
        ],
        profiles: [],
        editedIndex: -1,
        profileEdit: {
          id: '',
          libelle_organisation: '',
          adresses: '',
          directeur: '',
          telephone: '',
          email: '',
          longitude_organisations: '',
          latitude_organisations: '',
          logo_organisations: '',
        },
        profile: {
          libelle_organisation: '',
          adresses: '',
          directeur: '',
          telephone: '',
          email: '',
          longitude_organisations: '',
          latitude_organisations: '',
          logo_organisations: '',
        },
      }
    },
    mounted () {
      console.log(this.editedIndex)
      profilOng.getUsersProfile().then(response => {
        this.profiles = response.data
      })
    },
    computed: {
      ...mapGetters(['getThemeMode']),
      formTitle () {
        return this.editedIndex === -1 ? 'Ajouter un profil' : 'Modifier un profil'
      },
    },
    methods: {
      ...mapActions(['changeThemeLayout']),
      saveProfile () {
        if (this.editedIndex > -1) {
          profilOng.updateUser(this.profileEdit).then(response => {
            console.log(response.data)
            profilOng.getUsersProfile().then(response => {
              this.profiles = response.data
            })
            this.dialog = false
          }).catch(err => {
            console.log(err.data)
          })
        } else {
          profilOng.createUser(this.profileEdit).then(response => {
            console.log(response.data)
            profilOng.getUsersProfile().then(response => {
              this.profiles = response.data
            })
            this.dialog = false
          }).catch(err => {
            console.log(err.data)
          })
        }
        this.close()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.profileEdit = Object.assign({}, this.profile)
          this.editedIndex = -1
        })
      },
      editItem (item) {
        this.editedIndex = this.profiles.indexOf(item)
        this.profileEdit = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.profiles.indexOf(item)
        this.profileEdit = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        profilOng.deleteUser(this.profileEdit).then(response => {
          profilOng.getUsersProfile().then(response => {
            this.profiles = response.data
          })
        }).catch(err => {
          console.log(err.data)
        })
        this.closeDelete()
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.profileEdit = Object.assign({}, this.profile)
          this.editedIndex = -1
        })
      },
    },
  }
</script>

<style scoped>

</style>
